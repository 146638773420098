<template>
  <div>
    <v-row v-if="isInCustomImageMode" dense class="mx-0 my-3">
      <v-col cols="2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              fab
              @click="addingCustomImage = !addingCustomImage"
              v-on="on"
              :color="addingCustomImage ? 'primary' : undefined"
              ><v-icon>mdi-file-image-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('Add new custom image') }}</span>
        </v-tooltip>
      </v-col>
      <v-col v-if="selectedImageMeta" cols="10">
        <gli-text-field
          :label="$t('Custom image name')"
          class="ma-0 pa-0"
          v-model="selectedImageMeta.name"
          hide-details
          @change="changeCustomImageName($event)"
        ></gli-text-field>
      </v-col>
      <v-col v-if="!selectedImageMeta && addingCustomImage" cols="10">
        <v-row dense class="mx-0 my-0">
          <v-file-input
            class="ma-0 pr-3 file-input"
            :label="$t('Select image (max. 16 MB)')"
            prepend-icon=""
            clearable
            truncate-length="12"
            dense
            v-model="newCustomImageFile"
            show-size
            accept="image/png"
            hide-details
            :rules="[rules.imageSize]"
          >
          </v-file-input>
          <v-tooltip bottom :open-delay="700">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                color="primary"
                class="ma-0 pa-0"
                @click="startCustomImageUpload"
                :disabled="!newCustomImageFile || !imageOk"
                ><v-icon>mdi-upload</v-icon></v-btn
              ></template
            ><span>{{ $t('Upload image') }}</span>
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped></style>
<script>
import mapboxgl from 'mapbox-gl';
import { mapActions, mapGetters } from 'vuex';
import { getNewUniqueId } from '~/shared/utils';
import {
  booleanPointInPolygon as turfBooleanPointInPolygon,
  polygon as turfPolygon,
  point as turfPoint
} from '@turf/turf';
import ImageHandler from '../helpers/map/ImageHandler';
import constants from '~/shared/constants';
import rules from '../mixins/rules';
export default {
  name: 'ImagePanel',
  props: ['map'],

  data() {
    return {
      addingCustomImage: false,
      newCustomImageFile: undefined,
      customImageHandler: new ImageHandler(),
      imageMarkerTL: undefined,
      imageMarkerBR: undefined,
      imageMarkerTop: undefined,
      imageMarkerRot: undefined
    };
  },

  mounted() {
    this.map.on('click', this.clickHandler);
  },

  computed: {
    ...mapGetters('customImage', ['selectedImageMeta', 'imagesOnLevel']),
    ...mapGetters('level', ['editedLevel']),
    ...mapGetters('status', ['editorMode', 'currentDrawMode']),

    isInCustomImageMode() {
      return this.currentDrawMode === constants.DRAW_MODES.CUSTOM_IMAGE;
    },
    imageOk() {
      if (this.newCustomImageFile && this.newCustomImageFile.size < constants.IMAGE_SIZE_LIMIT) {
        return true;
      } else {
        return false;
      }
    }
  },

  watch: {
    currentDrawMode() {
      this.addingCustomImage = false;
      if (this.currentDrawMode !== constants.DRAW_MODES.CUSTOM_IMAGE) {
        this.deselectImage();
      }
    },

    editorMode() {
      if (!this.editorMode && this.addingCustomImage) {
        this.addingCustomImage = false;
      }

      if (!this.editorMode) {
        this.deselectImage();
      }
    },

    editedLevel: {
      async handler() {
        this.addingCustomImage = false;
      },
      immediate: true
    },

    selectedImageMeta(value) {
      this.hideImageMarkers();
      if (value) {
        this.showImageMarkers();
      }
    }
  },

  methods: {
    ...mapActions('image', ['setImageConvertInProgress']),
    ...mapActions('customImage', ['uploadImage', 'setImageMeta', 'selectImage', 'deselectImage']),
    ...mapActions('alert', ['error']),

    clickHandler(e) {
      if (this.currentDrawMode === constants.DRAW_MODES.CUSTOM_IMAGE) {
        this.deselectImage();
        let match = false;
        let i = this.imagesOnLevel.length - 1;
        while (!match && i >= 0) {
          let image = this.imagesOnLevel[i--];
          const point = turfPoint([e.lngLat.lng, e.lngLat.lat]);
          const polygon = turfPolygon([[...image.meta.coordinates, image.meta.coordinates[0]]]);
          if (turfBooleanPointInPolygon(point, polygon)) {
            this.selectImage(image);
            match = true;
          }
        }
      }
    },

    async startCustomImageUpload() {
      this.setImageConvertInProgress(true);

      try {
        await this.uploadCustomImage();
      } catch {
        this.error(
          this.$t('The selected file is not processable. Please select a valid .png file!')
        );
      } finally {
        this.setImageConvertInProgress(false);
      }
    },

    async uploadCustomImage() {
      if (this.newCustomImageFile.type !== 'image/png') {
        throw new Error('Not png');
      }

      const bounds = this.map.getBounds();
      const tl = [bounds._sw.lng, bounds._ne.lat];
      const br = [bounds._ne.lng, bounds._sw.lat];

      const { coordinates, mimeType, image } = await this.customImageHandler.getCornersAndMimeType({
        tl,
        br,
        file: this.newCustomImageFile
      });

      const id = getNewUniqueId();
      const meta = {
        id: id,
        levelId: this.editedLevel.id,
        size: this.newCustomImageFile.size,
        coordinates,
        mimeType
      };

      await this.uploadImage({ image, meta });

      this.newCustomImageFile = undefined;
      this.addingCustomImage = false;
    },

    changeCustomImageName(value) {
      this.setImageMeta({ ...this.selectedImageMeta, name: value });
    },

    showImageMarkers() {
      if (!this.selectedImageMeta) {
        return;
      }
      //to change arrow colors, use changeSvgColor(svgString, hexCode) from shared/utils.js
      const el1 = document.createElement('div');
      el1.style.backgroundImage = constants.STYLES.IMAGE_MANIPULATION_ARROWS.RESIZE;
      el1.style.width = constants.STYLES.IMAGE_MANIPULATION_ARROWS.WIDTH;
      el1.style.height = constants.STYLES.IMAGE_MANIPULATION_ARROWS.HEIGHT;
      el1.style.backgroundSize = '100%';

      const el2 = document.createElement('div');
      el2.style.backgroundImage = constants.STYLES.IMAGE_MANIPULATION_ARROWS.RESIZE;
      el2.style.width = constants.STYLES.IMAGE_MANIPULATION_ARROWS.WIDTH;
      el2.style.height = constants.STYLES.IMAGE_MANIPULATION_ARROWS.HEIGHT;
      el2.style.backgroundSize = '100%';

      const el3 = document.createElement('div');
      el3.style.backgroundImage = constants.STYLES.IMAGE_MANIPULATION_ARROWS.MOVE;
      el3.style.width = constants.STYLES.IMAGE_MANIPULATION_ARROWS.WIDTH;
      el3.style.height = constants.STYLES.IMAGE_MANIPULATION_ARROWS.HEIGHT;
      el3.style.backgroundSize = '100%';

      const el4 = document.createElement('div');
      el4.style.backgroundImage = constants.STYLES.IMAGE_MANIPULATION_ARROWS.ROTATE;
      el4.style.width = constants.STYLES.IMAGE_MANIPULATION_ARROWS.WIDTH;
      el4.style.height = constants.STYLES.IMAGE_MANIPULATION_ARROWS.HEIGHT;
      el4.style.backgroundSize = '100%';

      this.imageMarkerTL = new mapboxgl.Marker({
        draggable: true,
        element: el1
      })
        .setLngLat(this.selectedImageMeta.coordinates[0])
        .setRotation(this.customImageHandler.getBearing(this.selectedImageMeta.coordinates))
        .addTo(this.map);

      this.imageMarkerBR = new mapboxgl.Marker({
        draggable: true,
        element: el2
      })
        .setLngLat(this.selectedImageMeta.coordinates[2])
        .setRotation(this.customImageHandler.getBearing(this.selectedImageMeta.coordinates))
        .addTo(this.map);

      this.imageMarkerTop = new mapboxgl.Marker({
        draggable: true,
        element: el3
      })
        .setLngLat(this.customImageHandler.getTop(this.selectedImageMeta.coordinates))
        .setRotation(this.customImageHandler.getBearing(this.selectedImageMeta.coordinates))
        .addTo(this.map);

      this.imageMarkerRot = new mapboxgl.Marker({
        draggable: true,
        element: el4
      })
        .setLngLat(this.selectedImageMeta.coordinates[1])
        .setRotation(this.customImageHandler.getBearing(this.selectedImageMeta.coordinates))
        .addTo(this.map);

      let self = this;

      this.imageMarkerTL.on('dragstart', () => {
        self.customImageHandler.initCornerMove(
          self.selectedImageMeta.coordinates,
          self.selectedImageMeta.coordinates[2],
          self.selectedImageMeta.coordinates[0]
        );
        self.map.getCanvas().style.cursor = 'grabbing';
      });
      this.imageMarkerTL.on('drag', () => {
        const lngLat = self.imageMarkerTL.getLngLat();
        const coordinates = self.customImageHandler.calcCornerMoveCoords([lngLat.lng, lngLat.lat]);
        let mySource = self.map.getSource(`custom-image-${self.selectedImageMeta.id}`);
        mySource.setCoordinates(coordinates);
        self.setImageMarkerPositions(coordinates);
      });
      this.imageMarkerTL.on('dragend', () => {
        const lngLat = self.imageMarkerTL.getLngLat();
        const coordinates = self.customImageHandler.calcCornerMoveCoords([lngLat.lng, lngLat.lat]);
        const data = {
          ...this.selectedImageMeta,
          coordinates
        };
        self.setImageMeta(data);
        self.map.getCanvas().style.cursor = 'default';
        self.setImageMarkerPositions(coordinates);
      });

      this.imageMarkerBR.on('dragstart', () => {
        self.customImageHandler.initCornerMove(
          self.selectedImageMeta.coordinates,
          self.selectedImageMeta.coordinates[0],
          self.selectedImageMeta.coordinates[2]
        );
        self.map.getCanvas().style.cursor = 'grabbing';
      });
      this.imageMarkerBR.on('drag', () => {
        const lngLat = self.imageMarkerBR.getLngLat();
        const coordinates = self.customImageHandler.calcCornerMoveCoords([lngLat.lng, lngLat.lat]);
        let mySource = self.map.getSource(`custom-image-${self.selectedImageMeta.id}`);
        mySource.setCoordinates(coordinates);
        self.setImageMarkerPositions(coordinates);
      });
      this.imageMarkerBR.on('dragend', () => {
        const lngLat = self.imageMarkerBR.getLngLat();
        const coordinates = self.customImageHandler.calcCornerMoveCoords([lngLat.lng, lngLat.lat]);
        const data = {
          ...this.selectedImageMeta,
          coordinates
        };
        self.setImageMeta(data);
        self.map.getCanvas().style.cursor = 'default';
        self.setImageMarkerPositions(coordinates);
      });

      this.imageMarkerTop.on('dragstart', () => {
        self.customImageHandler.initTopMove(self.selectedImageMeta.coordinates);
        self.map.getCanvas().style.cursor = 'grabbing';
      });
      this.imageMarkerTop.on('drag', () => {
        const lngLat = self.imageMarkerTop.getLngLat();
        const coordinates = self.customImageHandler.calcTopMoveCoords([lngLat.lng, lngLat.lat]);
        let mySource = self.map.getSource(`custom-image-${self.selectedImageMeta.id}`);
        mySource.setCoordinates(coordinates);
        self.setImageMarkerPositions(coordinates);
      });
      this.imageMarkerTop.on('dragend', () => {
        const lngLat = self.imageMarkerTop.getLngLat();
        const coordinates = self.customImageHandler.calcTopMoveCoords([lngLat.lng, lngLat.lat]);
        const data = {
          ...this.selectedImageMeta,
          coordinates
        };
        self.setImageMeta(data);
        self.map.getCanvas().style.cursor = 'default';
        self.setImageMarkerPositions(coordinates);
      });

      this.imageMarkerRot.on('dragstart', () => {
        self.customImageHandler.initRotation(
          self.selectedImageMeta.coordinates,
          self.customImageHandler.getCenter(self.selectedImageMeta.coordinates),
          self.selectedImageMeta.coordinates[1]
        );
        self.map.getCanvas().style.cursor = 'grabbing';
      });
      this.imageMarkerRot.on('drag', () => {
        const lngLat = self.imageMarkerRot.getLngLat();
        const coordinates = self.customImageHandler.calcRotationCoords([lngLat.lng, lngLat.lat]);
        let mySource = self.map.getSource(`custom-image-${self.selectedImageMeta.id}`);
        mySource.setCoordinates(coordinates);
        self.setImageMarkerPositions(coordinates);
      });
      this.imageMarkerRot.on('dragend', () => {
        const lngLat = self.imageMarkerRot.getLngLat();
        const coordinates = self.customImageHandler.calcRotationCoords([lngLat.lng, lngLat.lat]);
        const data = {
          ...this.selectedImageMeta,
          coordinates
        };
        self.setImageMeta(data);
        self.map.getCanvas().style.cursor = 'default';
        self.setImageMarkerPositions(coordinates);
      });
    },

    hideImageMarkers() {
      if (this.imageMarkerTL) {
        this.imageMarkerTL.remove();
      }
      if (this.imageMarkerBR) {
        this.imageMarkerBR.remove();
      }
      if (this.imageMarkerTop) {
        this.imageMarkerTop.remove();
      }
      if (this.imageMarkerRot) {
        this.imageMarkerRot.remove();
      }

      this.imageMarkerTL = undefined;
      this.imageMarkerBR = undefined;
      this.imageMarkerTop = undefined;
      this.imageMarkerRot = undefined;
    },

    setImageMarkerPositions(coords) {
      if (this.imageMarkerTL) {
        this.imageMarkerTL.setLngLat(coords[0]);
        this.imageMarkerTL.setRotation(this.customImageHandler.getBearing(coords));
      }
      if (this.imageMarkerBR) {
        this.imageMarkerBR.setLngLat(coords[2]);
        this.imageMarkerBR.setRotation(this.customImageHandler.getBearing(coords));
      }
      if (this.imageMarkerTop && this.customImageHandler) {
        this.imageMarkerTop.setLngLat(this.customImageHandler.getTop(coords));
        this.imageMarkerTop.setRotation(this.customImageHandler.getBearing(coords));
      }
      if (this.imageMarkerRot) {
        this.imageMarkerRot.setLngLat(coords[1]);
        this.imageMarkerRot.setRotation(this.customImageHandler.getBearing(coords));
      }
    }
  },

  beforeDestroy() {
    this.hideImageMarkers();
    this.map.off('click', this.clickHandler);
  },

  components: {},
  mixins: [rules]
};
</script>
